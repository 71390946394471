<template>
  <div class="bmtj">
    <div class="shaixuan">
      <el-form class="tiaojian" ref="form" :model="form" label-width="100px">
        <el-form-item label="实训讲师">
          <el-input
            v-model="form.tea"
            placeholder="请输入实训讲师名"
            size="small"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="实训日期">
          <el-date-picker
            v-model="form.date"
            type="month"
            placeholder="按日期"
            size="small"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-select
            v-model="form.shixun"
            filterable
            placeholder="请选择实训活动"
            size="small"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="lists">
      <el-table v-loading="loading" :data="bms" border style="width: 100%">
        <el-table-column prop="s_name" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="s_tel" label="电话" width="180">
        </el-table-column>
        <el-table-column
          prop="jiangshi"
          label="讲师姓名"
          width="100"
        ></el-table-column>
        <el-table-column prop="name" label="实训来源"></el-table-column>
        <el-table-column prop="class_name" label="入学班级"></el-table-column>
        <el-table-column prop="state" label="报名状态"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="bottom">
        <el-pagination
          small
          layout="prev, pager, next"
          :page-size="20"
          :total="count"
          @current-change="getSXOnChangePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "BMTJ",
  data: function () {
    return {
      form: {
        date: "",
        hudong: "",
        tea: "",
      },
      shixuns: [],
      bm: [],
      datas: [],
      count: 0,
    };
  },
  async created() {
    //获取活动名称
    let res = await this.$datas.sxCheck;
    //获取实训活动
    this.shixuns = res.data.data;
    //获取实训报名信息
    res = await this.$datas.bmCheck;
    this.bm = res.data.data;
    this.count = res.data.count;
  },
  computed: {
    ...mapState(["loading"]),
    options() {
      let vals = [];
      for (let index in this.shixuns) {
        vals.push({
          value: this.shixuns[index].name,
          label: `${this.shixuns[index].name}(${this.shixuns[index].jiangshi})`,
        });
      }
      return vals;
    },
    bms() {
      let datas = [];
      for (let i in this.bm) {
        let item = this.bm[i];
        datas.push({ ...item, name: item.sx.name });
      }
      return datas;
    },
  },
  methods: {
    ...mapMutations(["changeLoading"]),
    getDate(date) {
      if (!date) {
        return "";
      }
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      return `${year}-${month}`;
    },
    async getSXOnChangePage(page) {
      this.$datas.meta = { page, form: { ...this.form } };
      //获取实训报名信息
      let res = await this.$datas.bmCheck;
      console.log(res.data.data);
      this.bm = res.data.data;
      this.count = res.data.count;
    },
  },
  watch: {
    form: {
      async handler(newV) {
        var date = this.getDate(newV.date);
        var data = { ...newV, date };
        //获取日期
        this.$datas.meta = { form: { ...data } };
        this.changeLoading(true);
        //开始网络请求
        let res = await this.$datas.bmCheck;
        this.bm = res.data.data;
        this.count = res.data.count;
        console.log(res.data);
      },
      deep: true,
    },
    async bm(newV) {
      let phones = [];
      for (let i in newV) {
        phones.push(newV[i].s_tel);
      }
      this.$datas.meta = { phones: phones.join(",") };
      let res = await this.$datas.bmStateCheck;
      if (res.status == 200) {
        let results = res.data.msg.data;
        let baomings = [];
        for (let i in results) {
          if (results[i].customer_Status_Name == "已结清") {
            baomings.push({
              ...this.bm[i],
              state: results[i].customer_Status_Name,
              class_name: results[i].class_Name,
            });
          }
        }
        this.datas = baomings;
      }
    },
  },
};
</script>
<style lang="less">
.bmtj {
  .shaixuan {
    .tiaojian {
      display: flex;
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
